export const getMandalayBayMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-mandalay-route.jpg'),
            mapLine: 'MandalayBayPath',
            venueBg:  require('@/assets/maps/mandalay.png'),
            venue: 'Mandalay Bay',
            distance: '0.7',
            singleLineVenue: true,
            threeLineVenue: false
        }
    ]
}
